import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

const RecipeState = {
  NEW: 'NEW',
  EDIT: 'EDIT',
};

class Recipe extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.RECIPE}/${id}`, opts);
  }

  get image() {
    return this.data.image || '';
  }

  get totalCalories() {
    return this.data.totalCalories;
  }

  get name() {
    return this.data.name;
  }

  get protein() {
    return this.data.protein;
  }

  get carbs() {
    return this.data.carbs;
  }

  get fat() {
    return this.data.fat;
  }

  get proteinPercentage() {
    return this.data.proteinPercentage;
  }

  get carbsPercentage() {
    return this.data.carbsPercentage;
  }

  get fatPercentage() {
    return this.data.fatPercentage;
  }

  get ingredients() {
    return this.data.ingredients;
  }

  get instructions() {
    return this.data.instructions;
  }

  get preparationTime() {
    return this.data.preparationTime;
  }

  get cookingTime() {
    return this.data.cookingTime;
  }

  get allergenTags() {
    return this.data.allergenTags || [];
  }

  get tags() {
    return this.data.tags || [];
  }

  get servings() {
    return this.data.servings;
  }

  get public() {
    return typeof this.data.public === 'boolean' ? this.data.public : true;
  }

  get originalRecipe() {
    return this.data.originalRecipe;
  }

  get deleted() {
    return !!this.data.deleted;
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  get isScalingDisabled() {
    return this.data.isScalingDisabled || false;
  }

  async updateArchiveStatus(isArchived) {
    await this.updateFields({ isArchived: !!isArchived });
  }

  static async addDoc(data) {
    const recipeCollection = new Collection(Collections.RECIPE);
    const doc = await recipeCollection.add(data);
    const recipeDoc = new Recipe(doc.id);
    await recipeDoc.init();
    return recipeDoc;
  }

  static async getAllRecipes() {
    const recipesCollection = new Collection(Collections.RECIPE, {
      createDocument: (src, opts) => new Recipe(src.id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => ref
        .orderBy('createdAt', 'desc'),
    });
    await recipesCollection.fetch();
    return recipesCollection;
  }

  // Gets all public recipes in the database
  static async getPublicRecipes() {
    const recipesCollection = new Collection(Collections.RECIPE, {
      createDocument: (src, opts) => new Recipe(src.id, opts),
      query: (ref) => ref
        .where('public', '==', true)
        .orderBy('createdAt', 'desc'),
    });
    await recipesCollection.fetch();
    return recipesCollection;
  }

  // Gets all private recipes created by the user
  static async getRecipesByCreator(userId, fetchArchivedRecipes) {
    const recipesCollection = new Collection(Collections.RECIPE, {
      createDocument: (src, opts) => new Recipe(src.id, opts),
      query: (ref) => {
        let query = ref
          .where('createdBy', '==', userId)
          .where('public', '==', false)
          .orderBy('createdAt', 'desc');

        if (!fetchArchivedRecipes) {
          query = query.where('isArchived', '==', false);
        }
        return query;
      },
    });
    await recipesCollection.fetch();
    return recipesCollection;
  }
}

export default Recipe;
export { RecipeState };
