import styled, { css } from 'styled-components';
import { IconButton } from '@mui/material';
import {
  CheckCircleOutline,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import Button from '../../../../components/Button';
import colors from '../../../../styles/colors';

const StyledButton = styled(Button)`
  height: 30px;
  width: unset;
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.shades.secondary8};
  height: 30px;
  width: auto;
  align-items: center;
  padding: 0 5px;
  overflow: hidden;
`;

const EventTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  flex-grow: 1;
`;

const CalendarWrapper = styled.div`
  .fc-toolbar-title, .fc-icon{
    font-size: 1rem !important;
  }
  .fc-button{
    line-height: 1 !important;
    margin: 5px;
  }
  .fc-toolbar {
    margin-bottom: 0.5em !important;
  }
  .fc-media-screen {
    background: #DDECFF;
    padding: 10px;
    height: 450px;
  }
  .fc-day-past {
    background: #EAF2FB;
  }
  .fc-daygrid-day-events {
    display: flex;
    flex-direction: column;
    max-height: 66px;
    overflow-x: hidden;
    margin-bottom: 2px;
    position: absolute;
    bottom: 0;
    min-height: 0 !important;
  }
  .fc-daygrid-day-number{
    width: 100%;
    height: 35px;
  }
  .fc-daygrid-day-events::-webkit-scrollbar {
    width: 5px;
  }
  .fc-daygrid-day-events::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .fc-daygrid-day-events::-webkit-scrollbar-thumb {
    background: #888;
  }
  .fc-daygrid-day-events::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 5px;
  color: ${colors.base.alpha};
  margin-right: 5px;
`;

const radioButtonStyles = css`
  width: 18px;
  height: 18px;
  > path {
    stroke: ${colors.base.primary};
  }
`;

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  ${radioButtonStyles}
`;

const StyledRadioButtonUnchecked = styled(RadioButtonUnchecked)`
  ${radioButtonStyles}
`;

export {
  StyledButton,
  EventContainer,
  EventTitle,
  CalendarWrapper,
  StyledIconButton,
  StyledCheckCircleOutline,
  StyledRadioButtonUnchecked,
};
