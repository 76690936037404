import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import useToast from '../../../../hooks/useToast';
import DialogRoundedModal, { ModalBody } from '../../../../../components/DialogRoundedModal';
import RecipesTable from '../../../../components/RecipesTable';

import AddRecipeAction from './components/AddRecipeAction';
import texts from './texts.json';

const TOAST_AUTOCLOSE_MS = 2500;

const AddRecipeModal = ({
  isOpen,
  onClose,
  onAddRecipe,
  selectedBucketId,
  mealTimes,
}) => {
  const { showToast } = useToast();

  const addRecipe = async (bucketId, recipe, isBulk = false) => {
    onAddRecipe(bucketId, recipe);
    if (!isBulk) {
      showToast(format(texts.recipeAdded, { bucketId }), { options: { autoClose: TOAST_AUTOCLOSE_MS } });
    }
  };

  const bulkAddRecipe = async (bucketId, recipes) => {
    recipes?.forEach((recipe) => onAddRecipe(bucketId, recipe, true));
    showToast(format(texts.recipesAdded, { bucketId }), { options: { autoClose: TOAST_AUTOCLOSE_MS } });
  };

  const title = selectedBucketId
    ? format(texts.bucketSelectedTitle, { bucket: selectedBucketId })
    : texts.title;

  return (
    <DialogRoundedModal
      open={isOpen}
      onClose={onClose}
      title={title}
      description={texts.description}
      fullScreen
      keepMounted
    >
      <ModalBody>
        <RecipesTable
          ActionsCellComponent={AddRecipeAction}
          extraActionsCellProps={{ addRecipe, mealTimes, selectedBucketId }}
          bulkActionComponent={AddRecipeAction}
          bulkActionComponentProps={{
            addRecipe: bulkAddRecipe,
            mealTimes,
            selectedBucketId,
            variant: 'square',
          }}
          bulkActionsEnabled
          actionCellWidth={10}
          showArchiveButton={false}
        />
      </ModalBody>
    </DialogRoundedModal>
  );
};

AddRecipeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddRecipe: PropTypes.func.isRequired,
  mealTimes: PropTypes.array.isRequired,
  selectedBucketId: PropTypes.string,
};

AddRecipeModal.defaultProps = {
  selectedBucketId: null,
};

export default AddRecipeModal;
