import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { forEachSeries } from 'p-iteration';
import format from 'string-template';
import {
  Form,
  Formik,
  FastField,
} from 'formik';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import * as Sentry from '@sentry/browser';

import { ReactComponent as CheckHeartIcon } from '../../../../assets/icons/v2/check-heart.svg';
import { getIngredientLine } from '../../../../utils/recipe';
import FirebaseContext from '../../../../context/FirebaseContext';
import UserContext from '../../../../context/UserContext';
import NavigationContext, {
  NavigationRouteType,
} from '../../../context/NavigationContext';
import Ingredient from '../../../Model/Ingredient';
import Recipe from '../../../Model/Recipe';
import useStorage, { StorageProcessState } from '../../../../hooks/useStorage';
import useSessionStore from '../../../../hooks/useSessionStore';
import useToast from '../../../hooks/useToast';
import { storagePaths, pathPlaceholder } from '../../../../utils/firebasePaths';
import { sanitizeForHandle } from '../../../../utils/string';
import { PrimaryButton, SaveButton } from '../../../../components/Button/ActionButtons';
import { CoachingActivity } from '../../../../utils/log';
import useLogger from '../../../../hooks/useLogger';
import {
  TitledActionContainer,
  TitledActionContainerFooter,
  TitledActionContainerHeader,
  TitledActionContainerSection,
} from '../styles';

import {
  ImageSelect,
  IngredientsForm,
  InstructionsForm,
  MacrosForm,
  TagsForm,
  CheckNutriFacts,
} from './components';
import {
  CheckDescription,
  Container,
  ContentContainer,
  FormImageContainer,
  FormSectionContainer,
  HeaderContainer,
  Label,
  PrepSection,
  StyledFormInput,
  ShortFormSection,
  StyledBackIcon,
  StyledFormRowScale,
  StyledSpinner,
  Title,
} from './styles';
import {
  initialValues as startValues,
  validationSchema,
} from './validation';
import {
  fieldName,
  ingredientField,
} from './formFields';
import texts from './texts';

const RecipeEditor = ({ recipeDoc, onBackClick, hasBackButton }) => {
  const [imageUrl, setImageUrl] = useState(recipeDoc ? recipeDoc.image : '');
  const [imageFile, setImageFile] = useState(null);
  const [nutriCheckIsLoading, setNutriCheckIsLoading] = useState(false);
  const [nutriCheckData, setNutriCheckData] = useState(null);
  const [numberOfServings, setNumberOfServings] = useState(null);
  const formRef = useRef();

  const { firebase } = useContext(FirebaseContext);
  const { routeType } = useContext(NavigationContext);
  const {
    userId: routeUserId,
  } = useContext(UserContext);

  const {
    uploadAttachment,
  } = useStorage();
  const {
    authUser: {
      uid: authUserId,
    },
  } = useSessionStore();
  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  const initialValues = useMemo(() => (
    recipeDoc ? recipeDoc.data : startValues
  ), [
    recipeDoc,
  ]);

  const onImageSelect = useCallback((file) => {
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
    }
    setImageFile(file);
    const dataUrl = URL.createObjectURL(file);
    setImageUrl(dataUrl);
  }, [
    imageUrl,
  ]);

  const onImageRemove = useCallback(() => {
    setImageFile(null);
    URL.revokeObjectURL(imageUrl);
    setImageUrl('');
  }, [
    imageUrl,
  ]);

  const getIngredientsLine = () => {
    const ingredients = formRef.current?.values?.ingredients?.filter(({ name }) => !!name);
    if (ingredients?.length === 0) {
      showToast(texts.errorNoIngredients, { warning: true });
    }
    return ingredients.map((ingredient) => getIngredientLine(ingredient)).join('\n');
  };

  const handleGoToNutritionixDemo = async () => {
    const ingredients = getIngredientsLine();
    if (!ingredients) {
      return;
    }
    const uri = `https://www.nutritionix.com/natural-demo?q=${encodeURI(ingredients)}&line_delimited`;
    window.open(
      uri,
      '_blank',
    );
  };

  const handleCheckNutritionFacts = async () => {
    try {
      setNutriCheckIsLoading(true);
      const ingredients = getIngredientsLine();
      if (!ingredients) {
        return;
      }
      const response = await firebase.remote('checkNutritionFacts', {
        ingredients,
      });
      const jsonResponse = await response.json();
      setNutriCheckData(jsonResponse?.checkedFacts?.foods);
      setNumberOfServings(formRef.current?.values?.[fieldName.SERVINGS]);
    } catch (error) {
      setNutriCheckData(null);
      Sentry.captureMessage(format(texts.errorOnNutritionixCall, { error }));
      showToast(format(texts.errorOnNutritionixCall, { error }), { error: true });
    } finally {
      setNutriCheckIsLoading(false);
    }
  };

  const handleSubmit = useCallback(async (values, actions) => {
    // First, validate remaining fields
    if (values[fieldName.TAGS].length === 0) {
      showToast(texts.validation.noTags, { error: true });
      actions.setSubmitting(false);
      return;
    }

    const recipeAllergens = [];
    // Then store ingredients on the ingredients collection
    await forEachSeries(values[fieldName.INGREDIENTS], async (ingredient) => {
      const handle = sanitizeForHandle(ingredient[ingredientField.NAME]);
      const ingredientDoc = await Ingredient.getByHandle(handle);
      if (ingredient[ingredientField.ALLERGEN_TAGS]) {
        recipeAllergens.push(...ingredient[ingredientField.ALLERGEN_TAGS]);
      }

      if (ingredientDoc) {
        const { allergenTags: currentAllergens } = ingredientDoc;
        // Merge allergen arrays and get unique values
        const newAllergens = [...new Set([...currentAllergens, ...ingredient[ingredientField.ALLERGEN_TAGS]])];
        await ingredientDoc.updateFields({
          [ingredientField.NAME]: ingredient[ingredientField.NAME],
          [ingredientField.ALLERGEN_TAGS]: newAllergens,
        });
      } else {
        await Ingredient.addDoc({
          [ingredientField.NAME]: ingredient[ingredientField.NAME],
          [ingredientField.ALLERGEN_TAGS]: ingredient[ingredientField.ALLERGEN_TAGS],
          handle,
        });
      }
    });

    // The recipe will be public if its created from the support dashboard.
    const isPublic = routeType === NavigationRouteType.SUPPORT;
    const isScalingDisabled = !!values[fieldName.SCALE] && JSON.parse(values[fieldName.SCALE]);
    if (recipeDoc) {
      let recipeDocument = recipeDoc;

      // Do we need to create a duplicate? Coaches can edit public recipes but they work on a copy.
      if (recipeDocument.public && !isPublic) {
        recipeDocument = await Recipe.addDoc({
          ...values,
          [fieldName.ALLERGEN_TAGS]: [...new Set(recipeAllergens)],
          [fieldName.CREATED_AT]: new Date(),
          [fieldName.CREATED_BY]: routeUserId,
          [fieldName.SCALE]: isScalingDisabled,
          public: false,
          isArchived: false,
          originalRecipe: recipeDoc.id,
        });
      } else {
        await recipeDocument.updateFields({
          ...values,
          [fieldName.ALLERGEN_TAGS]: [...new Set(recipeAllergens)],
          [fieldName.SCALE]: isScalingDisabled,
          [fieldName.UPDATED_AT]: new Date(),
          [fieldName.UPDATED_BY]: authUserId,
          isArchived: false,
        });
      }
      logCoachingActivity(CoachingActivity.UPDATED_RECIPE, { recipeId: recipeDocument.id });

      // Upload image changes and update recipe doc
      if (imageFile && recipeDocument.image !== imageUrl) {
        const storagePath = format(storagePaths.RECIPE_COLLECTION, {
          [pathPlaceholder.RECIPE_ID]: recipeDocument.id,
        });
        const lastDotIndex = imageFile.name.lastIndexOf('.');
        const storageResult = await uploadAttachment(imageUrl, imageFile.name.slice(lastDotIndex + 1), storagePath);
        if (storageResult.state === StorageProcessState.SUCCESS) {
          await recipeDocument.updateFields({
            image: storageResult.publicUrl,
          });
        }
      }
      showToast(texts.recipeUpdated);
    } else {
      // Create new recipe doc, use route user id as createdBy to handle private recipes
      const newRecipeDoc = await Recipe.addDoc({
        ...values,
        [fieldName.ALLERGEN_TAGS]: [...new Set(recipeAllergens)],
        [fieldName.CREATED_AT]: new Date(),
        [fieldName.CREATED_BY]: routeUserId,
        [fieldName.SCALE]: isScalingDisabled,
        public: isPublic,
      });
      logCoachingActivity(CoachingActivity.CREATED_RECIPE, { recipeId: newRecipeDoc.id });

      // Upload image and update recipe doc
      if (imageFile) {
        const storagePath = format(storagePaths.RECIPE_COLLECTION, {
          [pathPlaceholder.RECIPE_ID]: newRecipeDoc.id,
        });
        const lastDotIndex = imageFile.name.lastIndexOf('.');
        const storageResult = await uploadAttachment(imageUrl, imageFile.name.slice(lastDotIndex + 1), storagePath);
        if (storageResult.state === StorageProcessState.SUCCESS) {
          await newRecipeDoc.updateFields({
            image: storageResult.publicUrl,
          });
        }
      }

      // Reset form and show success toast
      setImageUrl('');
      setImageFile(null);
      actions.resetForm();
      showToast(texts.recipeSaved);
    }
  }, [
    uploadAttachment,
    imageUrl,
    imageFile,
    authUserId,
    routeUserId,
    showToast,
    recipeDoc,
    routeType,
    logCoachingActivity,
  ]);

  return (
    <Container>
      <HeaderContainer>
        <Title>{texts.recipesEditorTitle}</Title>
        {
          hasBackButton
          && (
          <PrimaryButton
            onClick={onBackClick}
            icon={<StyledBackIcon />}
            size="medium"
            variant="info"
          >
            {texts.back}
          </PrimaryButton>
          )
        }
      </HeaderContainer>
      <ContentContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formRef}
        >
          {({
            values,
            isSubmitting,
          }) => (
            <Form>
              <TitledActionContainer>
                <TitledActionContainerHeader>
                  {recipeDoc ? texts.editRecipe : texts.addRecipe}
                </TitledActionContainerHeader>
                <TitledActionContainerSection>
                  <FormImageContainer>
                    <Label>{`${texts.labels.image}:`}</Label>
                    <ImageSelect
                      image={imageUrl}
                      onImageSelect={onImageSelect}
                      onImageRemove={onImageRemove}
                    />
                  </FormImageContainer>
                  <FormSectionContainer>
                    <ShortFormSection>
                      <StyledFormInput
                        name={fieldName.NAME}
                        label={`${texts.field[fieldName.NAME].label}:`}
                      />
                      <StyledFormInput
                        name={fieldName.DESCRIPTION}
                        label={`${texts.field[fieldName.DESCRIPTION].label}:`}
                        multiline
                        rows={3}
                      />
                      <PrepSection>
                        <StyledFormInput
                          name={fieldName.SERVINGS}
                          label={`${texts.field[fieldName.SERVINGS].label}:`}
                          type="number"
                        />
                        <StyledFormInput
                          name={fieldName.PREPARATION_TIME}
                          label={`${texts.field[fieldName.PREPARATION_TIME].label}:`}
                          title={texts.prepUnit}
                        />
                        <StyledFormInput
                          name={fieldName.COOKING_TIME}
                          label={`${texts.field[fieldName.COOKING_TIME].label}:`}
                          title={texts.prepUnit}
                        />
                      </PrepSection>
                      <TagsForm />
                      <MacrosForm />
                    </ShortFormSection>
                    <IngredientsForm ingredients={values[fieldName.INGREDIENTS]} />
                    <ShortFormSection>
                      <InstructionsForm instructions={values[fieldName.INSTRUCTIONS]} />
                    </ShortFormSection>
                    <ShortFormSection>
                      <FastField name={fieldName.SCALE}>
                        {({ field }) => (
                          <div>
                            <Label>{`${texts.field[fieldName.SCALE].label}:`}</Label>
                            <StyledFormRowScale>
                              <RadioGroup
                                row
                                defaultValue={false}
                                {...field}
                              >
                                <FormControlLabel value={false} control={<Radio />} label={texts.yes} />
                                <FormControlLabel value control={<Radio />} label={texts.no} />
                              </RadioGroup>
                            </StyledFormRowScale>
                          </div>
                        )}
                      </FastField>
                    </ShortFormSection>
                  </FormSectionContainer>
                </TitledActionContainerSection>
                <TitledActionContainerFooter>
                  <SaveButton
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {texts.submitButton}
                  </SaveButton>
                </TitledActionContainerFooter>
              </TitledActionContainer>
            </Form>
          )}
        </Formik>

        <TitledActionContainer>
          <TitledActionContainerHeader>
            {texts.verifyNutritionFactsTitle}
          </TitledActionContainerHeader>
          <TitledActionContainerSection>
            {
              !(nutriCheckData || nutriCheckIsLoading)
              && <CheckDescription>{texts.verifyNutritionFactsDescription}</CheckDescription>
            }
            {nutriCheckIsLoading && <StyledSpinner>{texts.verifyNutritionFactsDescription}</StyledSpinner>}
            {
              (nutriCheckData && !nutriCheckIsLoading)
              && <CheckNutriFacts checkData={nutriCheckData} numberOfServings={numberOfServings} />
            }
          </TitledActionContainerSection>
          <TitledActionContainerFooter>
            <PrimaryButton
              onClick={handleCheckNutritionFacts}
              size="medium"
              icon={<CheckHeartIcon />}
            >
              {texts.checkNutritionFacts}
            </PrimaryButton>
            <PrimaryButton
              onClick={handleGoToNutritionixDemo}
              size="small"
              type="button"
              variant="light"
              icon={<CheckHeartIcon />}
            >
              {texts.checkNutritionFactsOnSite}
            </PrimaryButton>
          </TitledActionContainerFooter>
        </TitledActionContainer>
      </ContentContainer>
    </Container>
  );
};

RecipeEditor.propTypes = {
  recipeDoc: PropTypes.object,
  onBackClick: PropTypes.func,
  hasBackButton: PropTypes.bool,
};

RecipeEditor.defaultProps = {
  recipeDoc: null,
  onBackClick: () => {},
  hasBackButton: false,
};

export default RecipeEditor;
