import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';
import { ReactComponent as SaveIcon } from '../../../assets/icons/v2/save.svg';
import { StyledTextField } from '../../../components/Inputs';

const Title = styled(Typography)`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
`;
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Settings = styled.div`
  margin-top: 40px;
  padding: 16px;
  background-color: ${colors.base.gamma};
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0;
`;

const SettingContainer = styled.div`
  margin: 15px 0;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
`;

const SettingTitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
`;

const SettingsActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const ActionLabel = styled(Typography)`
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 400;
  color: ${colors.shades.primary1};
`;

const SettingsSeparator = styled.div`
  background-color: ${colors.base.gamma};
  height: 3px;
  margin: 10px 0;
`;

const DisallowedGendersContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const TextField = styled(StyledTextField)`
  .MuiInputBase-multiline {
    height: unset;
  }
`;

const StyledSaveIcon = styled(SaveIcon)`
  margin-right: 5px;
`;

export {
  Container,
  SettingContainer,
  Title,
  Settings,
  HeaderContainer,
  SettingTitle,
  SettingsActionContainer,
  ActionLabel,
  SettingsSeparator,
  DisallowedGendersContainer,
  TextField,
  StyledSaveIcon,
};
