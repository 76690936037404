import { Collection } from 'firestorter';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/paths';
import { CalendlyType } from '../utils/calendlyUtil';
import BaseDocument from './BaseDocument';
import User from './User';

const ChatSetting = {
  ENABLE_WORKOUT_NOTIFICATIONS: 'isWorkoutNotificationsEnabled',
  ENABLE_SUMMARY_NOTIFICATIONS: 'isSummaryNotificationsEnabled',
  ENABLE_FEEDBACK_NOTIFICATIONS: 'isFeedbackNotificationsEnabled',
};

const ISConfigOption = {
  IS_AUTOMATIC_MSG_ENABLED: 'automaticMsgEnabled',
  CALENDLY_LINK: 'calendlyLink',
  DISALLOWED_GENDERS: 'disallowedGenders',
  IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED: 'isEnabled',
  CONTRACT_LINK: 'directPurchaseLink',
  MESSAGE: 'messageContent',
  SUBJECT: 'subject',
};

const DisallowedGender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  PREFER_NOT_TO_ANSWER: 'PREFER_NOT_TO_ANSWER',
  OTHER: 'OTHER',
};

class Coach extends BaseDocument {
  get name() {
    return this.data.name;
  }

  get avatar() {
    return this.data.avatarUrl;
  }

  get picsUrls() {
    return this.data.picsUrls || [];
  }

  get photos() {
    return Coach.getAllPhotos(this.avatar, this.picsUrls);
  }

  static getAllPhotos(avatar, picsUrls) {
    return [
      avatar,
      ...picsUrls,
    ].filter((photo) => !!photo);
  }

  get summary() {
    return this.data.summary;
  }

  get bio() {
    return this.data.bio;
  }

  get certifications() {
    return this.data.certifications;
  }

  get focusAreas() {
    return this.data.focusAreas;
  }

  get location() {
    return this.data.location;
  }

  get interests() {
    return this.data.interests;
  }

  get isEligibleForCoachSelection() {
    return this.data.isEligibleForCoachSelection;
  }

  get currentLocation() {
    return this.data.location?.current;
  }

  get originalLocation() {
    return this.data.location?.original;
  }

  get initialClientCallCalendar() {
    return this.data?.calendlyURLs?.[CalendlyType.INITIAL_CLIENT_CALL]
      || this.data?.calendlyURLs?.[CalendlyType.DEFAULT];
  }

  get followUpCalendar() {
    return this.data?.calendlyURLs?.[CalendlyType.FOLLOW_UP_CALL]
      || this.data?.calendlyURLs?.[CalendlyType.DEFAULT];
  }

  get testData() {
    return this.data.testData || {};
  }

  get assignmentWeight() {
    return this.data.assignmentWeight;
  }

  get gender() {
    return this.data.gender;
  }

  get isS2Coach() {
    return this.data.isS2Coach;
  }

  get mealPlanConfig() {
    return this.data.mealPlanConfig || {};
  }

  get mealPlanIntroMessage() {
    return this.data.mealPlanIntroMessage || '';
  }

  get termsAndConditionsText() {
    return this.data.termsAndConditionsText || '';
  }

  get feesConfig() {
    return this.data.feesConfig || {};
  }

  get coachAssistant() {
    return this.data.coachAssistant || {};
  }

  get feedbackGenerationConfig() {
    return this.data.feedbackGenerationConfig || {};
  }

  get notificationsConfig() {
    return this.data.notificationsConfig || {};
  }

  get isEnrolledInISProgram() {
    return !!this.data.isEnrolledInISProgram;
  }

  get isACEnabled() {
    return !!this.data.isACEnabled;
  }

  get isAutomaticMsgEnabled() {
    return !!this.data.automaticMsgEnabled;
  }

  get calendlyLink() {
    return this.data.calendlyLink || '';
  }

  get leadEmailConfig() {
    return this.data.leadEmailConfig || {};
  }

  get isAutomaticEmailEnabled() {
    return !!this.leadEmailConfig.isEnabled;
  }

  get contractLink() {
    return this.leadEmailConfig.directPurchaseLink || '';
  }

  get disqualificationMessageContent() {
    return this.leadEmailConfig.messageContent || '';
  }

  get disqualificationMessageSubject() {
    return this.leadEmailConfig.subject || '';
  }

  get leadDisqualificationConfig() {
    return this.data.leadDisqualificationConfig || {};
  }

  get disallowedGenders() {
    return this.leadDisqualificationConfig.disallowedGenders || [];
  }

  setMealPlanIntroMessage(mealPlanIntroMessage) {
    this.updateFields({
      mealPlanConfig: {
        ...this.mealPlanConfig,
        mealPlanIntroMessage,
      },
    });
  }

  getCoachInfo = (variant) => {
    const {
      id,
      name,
      bio,
      avatar,
      picsUrls,
      certifications,
      interests,
      currentLocation,
      originalLocation,
      summary,
      testData,
    } = this;

    let variantData = {};

    if (variant && testData[variant]) {
      variantData = testData[variant];
    }

    const coachInfo = {
      id,
      name,
      bio,
      avatar,
      picsUrls,
      certifications,
      interests,
      currentLocation,
      originalLocation,
      summary,
      ...variantData,
    };

    return {
      ...coachInfo,
      photos: Coach.getAllPhotos(coachInfo.avatar, coachInfo.picsUrls),
    };
  };

  async updateNotificationsConfig(setting, value) {
    await this.updateFields({
      notificationsConfig: {
        ...this.notificationsConfig,
        [setting]: value,
      },
    });
  }

  async updateISConfigs(settings) {
    await this.set({
      [ISConfigOption.IS_AUTOMATIC_MSG_ENABLED]: settings[ISConfigOption.IS_AUTOMATIC_MSG_ENABLED],
      [ISConfigOption.CALENDLY_LINK]: settings[ISConfigOption.CALENDLY_LINK],
      leadDisqualificationConfig: {
        [ISConfigOption.DISALLOWED_GENDERS]: settings[ISConfigOption.DISALLOWED_GENDERS],
      },
      leadEmailConfig: {
        [ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]:
          settings[ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED],
        [ISConfigOption.CONTRACT_LINK]: settings[ISConfigOption.CONTRACT_LINK],
        [ISConfigOption.MESSAGE]: settings[ISConfigOption.MESSAGE],
        [ISConfigOption.SUBJECT]: settings[ISConfigOption.SUBJECT],
      },
    }, { merge: true });
  }

  static async getAllCoaches() {
    const coachCollection = new Collection(firestorePaths.COACH_COLLECTION, {
      createDocument: (source, options) => new Coach(source, options),
    });
    await Coach.initCollection(coachCollection);

    return coachCollection.hasDocs ? coachCollection.docs : [];
  }

  static async getAllExternalCoaches() {
    const coachCollection = new Collection(firestorePaths.COACH_COLLECTION, {
      createDocument: (source, options) => new Coach(source, options),
      query: (query) => query
        .where('isS2Coach', '==', false)
        .where('isActive', '==', true),
    });
    await Coach.initCollection(coachCollection);

    return coachCollection;
  }

  static async getCoachById(coachId) {
    const coach = new Coach(format(firestorePaths.COACH_DOC, {
      [pathPlaceholder.COACH_ID]: coachId,
    }));
    await coach.init();
    return coach.exists ? coach : null;
  }

  static async isCoach(userId) {
    const coachDoc = new Coach(format(firestorePaths.COACH_DOC, {
      [pathPlaceholder.COACH_ID]: userId,
    }));

    await coachDoc.init();

    return coachDoc.exists;
  }

  static async getBroadcastMessageUsers(coachId) {
    const userCollection = new Collection(firestorePaths.USER,
      {
        query: (ref) => ref
          .where('assignedCoach', '==', coachId)
          .where('breakEndDate', '<', new Date())
          .where('flags.isActive', '==', true),
        createDocument: (source, options) => new User(source, options),
      });
    await User.initCollection(userCollection);
    return userCollection.hasDocs ? userCollection.docs : [];
  }
}

export default Coach;
export {
  ChatSetting,
  ISConfigOption,
  DisallowedGender,
};
