import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import format from 'string-template';

import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import LoadingPage from '../../../../../../components/LoadingPage';
import { DateFormat } from '../../../../../../utils/date';
import {
  cancelSubscription,
  listInvoices,
  updateSubscription,
  retryInvoicePayment,
  pauseStripeSubscription,
} from '../../../../../../pages/Onboarding/Subscription/stripe/SubscriptionRemoteRequestHandler';
import FirebaseContext from '../../../../../../context/FirebaseContext';
import User from '../../../../../../Model/User';
import UserContract from '../../../../../../Model/UserContract';
import Plan from '../../../../../../Model/Plan';
import Subscription, { subscriptionStatus as activeSubscriptionStatus } from '../../../../../../Model/Subscription';
import { StripeSubscriptionState } from '../../../../../../pages/Onboarding/Subscription/SubscriptionMetadata';
import useSessionStore from '../../../../../../hooks/useSessionStore';
import { formatCurrencyCents } from '../../../../../../utils/formatters';
import colors from '../../../../../../styles/colors';
import useLogger from '../../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../../utils/log';
import ReonboardingContractModal from '../../../../../components/ContractModal/Modals/ReonboardingContractModal';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import CopyButton from '../../../../../../components/CopyButton';
import useToast from '../../../../../hooks/useToast';
import { ClientStatus, filterChecks } from '../../../../../utils/statusFilter';
import { getContractObj } from '../../../../../utils/userContract';

import EmptyView from '../../EmptyView';
import InfoItem from '../../InfoItem';

import VoidInvoiceModal from '../VoidInvoiceModal';
import SubscriptionCancelDialog from '../SubscriptionCancelModal';
import StartDateUpdateModal from '../StartDateUpdateModal';
import SubscriptionPauseModal from '../SubscriptionPauseModal';
import {
  Container,
  DateText,
  HeaderContainer,
  TextContainer,
  TitleText,
  InfoWrapper,
  DataContainer,
  Title,
  StyledTableContainer,
  StyledTableCell,
  StyledButton,
  StyledCancelSubscriptionIcon,
  StyledLink,
  EditSubscriptionIcon,
  StyledPauseSubscriptionIcon,
  StyledReonboardIcon,
} from './styles';
import texts from './texts.json';

const BillingSummary = ({
  userDoc,
  userContractDoc,
  subscriptionDoc,
}) => {
  const [planDoc, setPlanDoc] = useState();
  const [subscriptionData] = useState(subscriptionDoc?.subscriptionData);
  const [invoiceList, setInvoiceList] = useState([]);
  const [error, setError] = useState();
  const [isReady, setIsReady] = useState(false);
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [showConfirmPauseDialog, setShowConfirmPauseDialog] = useState(false);
  const [showStartDateModal, setShowStartDateModal] = useState(false);
  const [showUserContractModal, setShowUserContractModal] = useState(false);
  const [isUpdatingInProgress, setIsUpdatingInProgress] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState();
  const [showRetryPaymentDialog, setShowRetryPaymentDialog] = useState(false);
  const [showVoidInvoiceDialog, setShowVoidInvoiceDialog] = useState(false);
  const [isRetryingInProgress, setIsRetryingInProgress] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState();
  const isComponentMountedRef = useComponentMounted();
  const { firebase: { remote } } = useContext(FirebaseContext);
  const { showToast } = useToast();
  const { isCoach, isAdmin } = useSessionStore();
  const { logCoachingActivity } = useLogger();

  useEffect(() => {
    const init = async () => {
      const {
        planId,
      } = userDoc;

      let invoices;
      if (subscriptionData) {
        invoices = await listInvoices(userDoc.id, remote);
      }

      let plan;
      if (planId) {
        plan = await Plan.getPlanById(planId);
      }

      if (isComponentMountedRef.current) {
        if (plan) {
          setPlanDoc(plan);
        }

        if (subscriptionDoc) {
          setStripeAccountId(subscriptionDoc.stripeAccountId);
          if (invoices && Array.isArray(invoices)) {
            setInvoiceList(invoices);
          } else {
            setError(invoices?.error.message || invoices?.error);
          }
        }
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    userDoc,
    isReady,
    isComponentMountedRef,
    subscriptionData,
    remote,
    subscriptionDoc,
  ]);

  const cancelClientSubscription = useCallback(async (cancellationReason, cancelType) => {
    setShowConfirmCancelDialog(false);
    setIsUpdatingInProgress(true);
    const { isSuccess } = await cancelSubscription({
      subscriptionId: subscriptionData.id,
      active: true,
      remote,
      cancellationReason,
      cancelType,
    });
    if (isSuccess) {
      showToast(format(texts.cancelSuccess, {
        subscriptionId: subscriptionData.id,
      }));

      logCoachingActivity(CoachingActivity.CANCELED_USER, {
        cancelType,
        cancellationReason,
        clientId: userDoc.id,
      });
    } else {
      showToast(format(texts.cancelError, {
        subscriptionId: subscriptionData.id,
      }), { error: true });
    }
    setIsUpdatingInProgress(false);
  }, [
    subscriptionData,
    remote,
    showToast,
    userDoc,
    logCoachingActivity,
  ]);

  const updateClientSubscription = useCallback(async (updateParams, cancellationReason, cancelType) => {
    setShowConfirmCancelDialog(false);
    setIsUpdatingInProgress(true);
    setShowStartDateModal(false);

    const { updatedSubscription, error: apiError } = await updateSubscription({
      subscriptionId: subscriptionData.id,
      updateParams,
      remote,
      cancellationReason,
      cancelType,
    });
    if (updatedSubscription) {
      const successText = cancellationReason ? texts.futureCancelSuccess : texts.updateSuccess;

      /* We only execute this part if we are updating the start date, not when cancelling the subscription
        It updates the contract terms when changing start date */
      if (!cancelType) {
        const contractObj = getContractObj(userContractDoc);
        // Update the startDate
        contractObj.startDate = moment.unix(updateParams.trial_end);
        // Update the doc
        await userContractDoc.updateContractTermsFromContractData(contractObj);
      }

      showToast(format(successText, {
        subscriptionId: subscriptionData.id,
      }));
    } else if (apiError) {
      showToast(format(texts.updateError, {
        subscriptionId: subscriptionData.id,
        error: apiError.message || '',
      }), { error: true });
    }
    setIsUpdatingInProgress(false);
  }, [
    subscriptionData,
    remote,
    showToast,
    userContractDoc,
  ]);

  const pauseClientSubscription = useCallback(async (pausingReason, breakEndDate, pausedPeriodInMonths) => {
    setShowConfirmPauseDialog(false);
    setIsUpdatingInProgress(true);
    const { isSuccess } = await pauseStripeSubscription({
      subscriptionId: subscriptionData.id,
      remote,
      pausingReason,
      breakEndDate,
    });
    if (isSuccess) {
      showToast(format(texts.pauseSuccess, {
        subscriptionId: subscriptionData.id,
      }));
      logCoachingActivity(CoachingActivity.PAUSED_USER, {
        pausingReason,
        pausedPeriodInMonths,
        clientId: userDoc.id,
      });
    } else {
      showToast(format(texts.pauseError, {
        subscriptionId: subscriptionData.id,
      }), { error: true });
    }
    if (isComponentMountedRef) {
      setIsUpdatingInProgress(false);
    }
  }, [
    subscriptionData,
    remote,
    showToast,
    isComponentMountedRef,
    logCoachingActivity,
    userDoc,
  ]);

  const retryCurrentInvoicePayment = useCallback(async () => {
    setShowRetryPaymentDialog(false);
    setIsRetryingInProgress(true);
    const { invoice, error: stripeError } = await retryInvoicePayment({
      invoiceId: currentInvoice?.id,
      stripeAccountId,
      remote,
    });

    const invoiceDate = (currentInvoice
      && moment.unix(currentInvoice.created).format(DateFormat.MONTH_NAME_DATE_FORMAT)) || '';
    if (stripeError) {
      showToast(format(texts.retryError, {
        invoiceDate,
        error: stripeError.message || '',
      }), { error: true });
    } else if (invoice) {
      showToast(format(texts.retrySuccess, {
        invoiceDate,
      }));
      setIsReady(false);
    }
    setIsRetryingInProgress(false);
  }, [
    stripeAccountId,
    remote,
    showToast,
    currentInvoice,
  ]);

  const handleRetryButtonClick = useCallback((invoice) => {
    setCurrentInvoice(invoice);
    setShowRetryPaymentDialog(true);
  }, []);

  const handleVoidInvoiceButtonClick = useCallback((invoice) => {
    setCurrentInvoice(invoice);
    setShowVoidInvoiceDialog(true);
  }, []);

  const onInvoiceVoided = useCallback(async () => {
    setShowVoidInvoiceDialog(false);
    const invoices = await listInvoices(userDoc.id, remote);
    if (isComponentMountedRef.current) {
      setInvoiceList(invoices);
    }
  }, [
    userDoc,
    remote,
    isComponentMountedRef,
  ]);

  const shouldShowModal = useMemo(() => (
    !error
    && !!userDoc.subscriptionStatus
    && Object.keys(activeSubscriptionStatus).includes(userDoc.subscriptionStatus)
    && (isCoach || isAdmin)
  ), [
    error,
    userDoc.subscriptionStatus,
    isCoach,
    isAdmin,
  ]);

  const shouldShowCancellationOption = useMemo(() => (
    !error
    && !!userDoc.subscriptionStatus
    && [...Object.keys(activeSubscriptionStatus), StripeSubscriptionState.UNPAID.toUpperCase()]
      .includes(userDoc.subscriptionStatus)
    && (isCoach || isAdmin)
  ), [
    error,
    userDoc.subscriptionStatus,
    isCoach,
    isAdmin,
  ]);

  const shouldShowNewContractOption = useMemo(() => (
    !error
    && !!userDoc.subscriptionStatus
    && userDoc.subscriptionStatus === StripeSubscriptionState.CANCELED.toUpperCase()
    && (isCoach || isAdmin)
  ), [
    error,
    userDoc.subscriptionStatus,
    isCoach,
    isAdmin,
  ]);
  const shouldShowPauseOption = useMemo(() => {
    const pifPeriodEndDate = moment(userDoc.serviceStartAt)
      .add(userContractDoc?.initialTerm, 'months');
    const isAfterPifPeriod = moment().isAfter(pifPeriodEndDate);
    return (!error
      && !!userDoc.subscriptionStatus
      && [...Object.keys(activeSubscriptionStatus), StripeSubscriptionState.UNPAID.toUpperCase()]
        .includes(userDoc.subscriptionStatus)
      // We should not show pause button when user is still in PIF period in PIF contracts or
      // when client get discount for 1st month and still in first month
      && (
        isAfterPifPeriod
        || (userContractDoc?.initialTerm === 1
          && userContractDoc?.initialPaymentInCents === userContractDoc?.totalPriceInCents
        )
      )
    );
  }, [
    error,
    userDoc.subscriptionStatus,
    userDoc.serviceStartAt,
    userContractDoc,
  ]);

  const cancelText = useMemo(() => {
    if (!subscriptionData) {
      return '';
    }

    const cancelDate = moment.unix(subscriptionData.cancel_at);
    if (cancelDate.isAfter(moment())) {
      return `(canceling on ${cancelDate.format(DateFormat.MONTH_NAME_DATE_FORMAT)})`;
    }
    if (subscriptionData.status === StripeSubscriptionState.CANCELED) {
      return `(${cancelDate.format(DateFormat.MONTH_NAME_DATE_FORMAT)})`;
    }
    return '';
  }, [subscriptionData]);

  if (!isReady) {
    return <LoadingPage />;
  }

  if (!subscriptionData) {
    return <EmptyView text={texts.noSubscription} />;
  }

  if (!planDoc && !userContractDoc) {
    return <EmptyView text={texts.noInfo} />;
  }

  const price = userContractDoc
    ? formatCurrencyCents(userContractDoc.totalPriceInCents, userContractDoc.currency)
    : formatCurrencyCents(planDoc.totalPriceInCents, planDoc.currency);

  return (
    <Container>
      <HeaderContainer>
        <TextContainer>
          <TitleText>
            {texts.billingSummary}
          </TitleText>
          <DateText>
            {`Started ${moment.unix(subscriptionData.created)
              .format(DateFormat.MONTH_NAME_DATE_FORMAT)}`}
          </DateText>
        </TextContainer>
        {shouldShowModal
          && filterChecks[ClientStatus.FUTURE_START](userDoc)
          && (
            <StyledButton
              startIcon={<EditSubscriptionIcon />}
              onClick={() => setShowStartDateModal(true)}
            >
              {texts.updateStartDate}
            </StyledButton>
          )}
        {shouldShowCancellationOption
          && (
            <StyledButton
              startIcon={<StyledCancelSubscriptionIcon />}
              onClick={() => setShowConfirmCancelDialog(true)}
            >
              {texts.cancelSubscription}
            </StyledButton>
          )}
        {shouldShowPauseOption
          && (
            <StyledButton
              startIcon={<StyledPauseSubscriptionIcon />}
              onClick={() => setShowConfirmPauseDialog(true)}
            >
              {texts.pauseSubscription}
            </StyledButton>
          )}
        {shouldShowNewContractOption
          && (
            <StyledButton
              startIcon={<StyledReonboardIcon />}
              onClick={() => setShowUserContractModal(true)}
            >
              {texts.reonboardClient}
            </StyledButton>
          )}
      </HeaderContainer>
      <InfoWrapper>
        <InfoItem
          labelColor={colors.shades.gamma2}
          contentColor={colors.base.beta}
          label={texts.package}
        >
          {planDoc?.planTitle || planDoc?.planCode || texts.emptyText}
        </InfoItem>
        <InfoItem
          labelColor={colors.shades.gamma2}
          contentColor={colors.base.beta}
          label={texts.price}
        >
          {price}
        </InfoItem>
        <InfoItem
          labelColor={colors.shades.gamma2}
          contentColor={colors.base.beta}
          label={texts.status}
        >
          {userDoc.subscriptionStatus || texts.emptyText}
          {subscriptionData.cancel_at && (
            <DateText>
              {cancelText}
            </DateText>
          )}
        </InfoItem>
        <InfoItem
          labelColor={colors.shades.gamma2}
          contentColor={colors.base.beta}
          label={texts.nextBilling}
        >
          {(!!userDoc.subscriptionStatus
            && Object.keys(activeSubscriptionStatus).includes(userDoc.subscriptionStatus)
            && moment.unix(subscriptionDoc.subscriptionData.current_period_end)
              .format(DateFormat.MONTH_NAME_DATE_FORMAT))
            || texts.emptyText}
        </InfoItem>
      </InfoWrapper>
      <DataContainer>
        <Title>{texts.invoices}</Title>
        {invoiceList.length === 0 && <EmptyView text={error || texts.noInvoices} />}
        {invoiceList.length > 0 && (
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>{texts.invoiceNo}</StyledTableCell>
                  <StyledTableCell>{texts.amount}</StyledTableCell>
                  <StyledTableCell>{texts.dateCreated}</StyledTableCell>
                  <StyledTableCell>{texts.paymentDate}</StyledTableCell>
                  <StyledTableCell>{texts.status}</StyledTableCell>
                  <StyledTableCell>{texts.action}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceList.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <StyledTableCell>
                      <StyledLink href={invoice.hosted_invoice_url} target="_blank">
                        {invoice.number}
                      </StyledLink>
                      <CopyButton value={invoice.hosted_invoice_url} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatCurrencyCents(invoice.total, invoice.currency)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment.unix(invoice.created).format(DateFormat.MONTH_NAME_DATE_FORMAT)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.status_transitions?.paid_at
                        ? moment.unix(invoice.status_transitions?.paid_at).format(DateFormat.MONTH_NAME_DATE_FORMAT)
                        : texts.emptyText}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.status}
                    </StyledTableCell>
                    <StyledTableCell>
                      {(invoice.status === 'open' && subscriptionData.status !== StripeSubscriptionState.CANCELED) ? (
                        <>
                          <StyledButton onClick={() => handleRetryButtonClick(invoice)}>
                            {texts.retryPayment}
                          </StyledButton>
                          <StyledButton onClick={() => handleVoidInvoiceButtonClick(invoice)}>
                            {texts.voidInvoice}
                          </StyledButton>
                        </>
                      ) : texts.emptyText}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </DataContainer>
      <LoadingOverlay isLoading={isUpdatingInProgress || isRetryingInProgress} />
      <SubscriptionCancelDialog
        isOpen={showConfirmCancelDialog}
        onCancel={() => setShowConfirmCancelDialog(false)}
        dialogTexts={{
          title: texts.confirmCancel.title,
          content: format(texts.confirmCancel.content, {
            userName: userDoc.name,
          }),
        }}
        subscription={subscriptionData}
        updateClientSubscription={updateClientSubscription}
        cancelClientSubscription={cancelClientSubscription}
        userContractDoc={userContractDoc}
        userDoc={userDoc}
      />
      <StartDateUpdateModal
        isOpen={showStartDateModal}
        onCancel={() => setShowStartDateModal(false)}
        dialogTexts={{
          title: texts.confirmNewStartDate.title,
          content: format(texts.confirmNewStartDate.content, {
            userName: userDoc.name,
          }),
        }}
        currentStartDate={moment(userDoc.serviceStartAt)}
        updateClientSubscription={updateClientSubscription}
      />
      <ConfirmDialog
        isOpen={showRetryPaymentDialog}
        onConfirm={retryCurrentInvoicePayment}
        onCancel={() => setShowRetryPaymentDialog(false)}
        dialogTexts={{
          title: texts.confirmRetry.title,
          content: format(texts.confirmRetry.content, {
            userName: userDoc.name,
            month: currentInvoice && moment.unix(currentInvoice.created).format(DateFormat.MONTH_NAME),
          }),
        }}
      />
      {!!showVoidInvoiceDialog && (
        <VoidInvoiceModal
          isOpen={showVoidInvoiceDialog}
          invoice={currentInvoice}
          onClose={() => setShowVoidInvoiceDialog(false)}
          onInvoiceVoided={onInvoiceVoided}
          stripeAccountId={stripeAccountId}
        />
      )}
      <SubscriptionPauseModal
        isOpen={showConfirmPauseDialog}
        onCancel={() => setShowConfirmPauseDialog(false)}
        subscription={subscriptionData}
        userDoc={userDoc}
        pauseClientSubscription={pauseClientSubscription}
      />
      {!!showUserContractModal && (
        <ReonboardingContractModal
          user={userDoc}
          coachId={userDoc.assignedCoach}
          showModal={showUserContractModal}
          onClose={() => setShowUserContractModal(false)}
        />
      )}
    </Container>
  );
};

BillingSummary.propTypes = {
  userDoc: PropTypes.instanceOf(User).isRequired,
  subscriptionDoc: PropTypes.instanceOf(Subscription),
  userContractDoc: PropTypes.instanceOf(UserContract),
};

BillingSummary.defaultProps = {
  userContractDoc: null,
  subscriptionDoc: null,
};

export default compose(
  observer,
)(BillingSummary);
