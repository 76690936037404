import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useToast from '../../../../hooks/useToast';
import Coach, { DisallowedGender, ISConfigOption } from '../../../../../Model/Coach';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import LabelCheckbox from '../../../../components/LabelCheckbox';
import Button from '../../../../../components/v2/Button';

import { SettingOption } from '../../utils';
import {
  ActionLabel,
  HeaderContainer,
  SettingContainer,
  SettingsActionContainer,
  SettingsSeparator,
  SettingTitle,
  DisallowedGendersContainer,
  TextField,
  StyledSaveIcon,
} from '../../styles';
import texts from './texts';

const disallowedGenderOptions = Object.values(DisallowedGender).map((value) => ({
  value: texts.disallowedGenders[value],
  label: texts.disallowedGenders[value],
}));

const ISConfigs = ({
  selectedCoach,
  setIsLoading,
}) => {
  const [coachDoc, setCoachDoc] = useState(null);
  const [iSSettings, setISSettings] = useState({
    [ISConfigOption.IS_AUTOMATIC_MSG_ENABLED]: false,
    [ISConfigOption.CALENDLY_LINK]: '',
    [ISConfigOption.DISALLOWED_GENDERS]: [],
    [ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]: false,
    [ISConfigOption.CONTRACT_LINK]: '',
    [ISConfigOption.MESSAGE]: '',
    [ISConfigOption.SUBJECT]: '',
  });

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const coachDocument = await Coach.getCoachById(selectedCoach.id);
      if (isComponentMountedRef.current) {
        setCoachDoc(coachDocument);
        setISSettings({
          [ISConfigOption.IS_AUTOMATIC_MSG_ENABLED]: coachDocument.isAutomaticMsgEnabled,
          [ISConfigOption.CALENDLY_LINK]: coachDocument.calendlyLink,
          [ISConfigOption.DISALLOWED_GENDERS]: coachDocument.disallowedGenders,
          [ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]:
            coachDocument.isAutomaticEmailEnabled,
          [ISConfigOption.CONTRACT_LINK]: coachDocument.contractLink,
          [ISConfigOption.MESSAGE]: coachDocument.disqualificationMessageContent,
          [ISConfigOption.SUBJECT]: coachDocument.disqualificationMessageSubject,
        });
      }
      setIsLoading(false);
    };
    if (selectedCoach) {
      init();
    }
  }, [
    selectedCoach,
    setIsLoading,
    isComponentMountedRef,
  ]);

  const handleISConfigSettingsSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await coachDoc.updateISConfigs(iSSettings);
      showToast(texts.iSConfigurationsSaveSuccessful);
    } catch (error) {
      showToast(texts.iSConfigurationsSaveFailed, { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [
    coachDoc,
    iSSettings,
    showToast,
    isComponentMountedRef,
    setIsLoading,
  ]);

  const isContractLinkError = iSSettings[ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]
    && !iSSettings[ISConfigOption.CONTRACT_LINK];

  const isMsgBodyError = iSSettings[ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]
    && !iSSettings[ISConfigOption.MESSAGE];

  const isSubjectError = iSSettings[ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]
    && !iSSettings[ISConfigOption.SUBJECT];

  return (
    <SettingContainer>
      <HeaderContainer>
        <SettingTitle>{texts.iSConfigurations}</SettingTitle>
      </HeaderContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.automaticLeadMsgs}</ActionLabel>
        <RadioButtonGroup
          options={[
            { label: texts.enabled, value: SettingOption.ENABLED },
            { label: texts.disabled, value: SettingOption.DISABLED },
          ]}
          selectedOption={iSSettings[ISConfigOption.IS_AUTOMATIC_MSG_ENABLED]
            ? SettingOption.ENABLED : SettingOption.DISABLED}
          onOptionChange={(value) => setISSettings((prevState) => ({
            ...prevState,
            [ISConfigOption.IS_AUTOMATIC_MSG_ENABLED]: value === SettingOption.ENABLED,
          }))}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.calendlyLink}</ActionLabel>
        <TextField
          value={iSSettings[ISConfigOption.CALENDLY_LINK]}
          onChange={(event) => setISSettings((prevState) => ({
            ...prevState,
            [ISConfigOption.CALENDLY_LINK]: event.target.value,
          }))}
          placeholder={texts.calendlyLinkPlaceholderText}
        />
      </SettingsActionContainer>
      <SettingsSeparator />
      <SettingsActionContainer>
        <ActionLabel>{texts.disallowGenders}</ActionLabel>
        <DisallowedGendersContainer>
          {disallowedGenderOptions.map((option) => (
            <LabelCheckbox
              key={option.value}
              description={option.label}
              isChecked={iSSettings[ISConfigOption.DISALLOWED_GENDERS].includes(option.value)}
              onChange={() => setISSettings((prevState) => ({
                ...prevState,
                [ISConfigOption.DISALLOWED_GENDERS]: prevState[ISConfigOption.DISALLOWED_GENDERS]
                  .includes(option.value)
                  ? prevState[ISConfigOption.DISALLOWED_GENDERS].filter((value) => value !== option.value)
                  : [...prevState[ISConfigOption.DISALLOWED_GENDERS], option.value],
              }))}
            />
          ))}
        </DisallowedGendersContainer>
      </SettingsActionContainer>
      <SettingsSeparator />
      <SettingsActionContainer>
        <ActionLabel>{texts.automaticDisqualificationEmail}</ActionLabel>
        <RadioButtonGroup
          options={[
            { label: texts.enabled, value: SettingOption.ENABLED },
            { label: texts.disabled, value: SettingOption.DISABLED },
          ]}
          selectedOption={iSSettings[ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]
            ? SettingOption.ENABLED : SettingOption.DISABLED}
          onOptionChange={(value) => setISSettings((prevState) => ({
            ...prevState,
            [ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]: value === SettingOption.ENABLED,
          }))}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.contractLink}</ActionLabel>
        <TextField
          value={iSSettings[ISConfigOption.CONTRACT_LINK]}
          onChange={(event) => setISSettings((prevState) => ({
            ...prevState,
            [ISConfigOption.CONTRACT_LINK]: event.target.value,
          }))}
          placeholder={texts.contractLinkPlaceholderText}
          error={isContractLinkError}
          helperText={isContractLinkError && texts.required}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.emailBody}</ActionLabel>
        <TextField
          value={iSSettings[ISConfigOption.MESSAGE]}
          onChange={(event) => setISSettings((prevState) => ({
            ...prevState,
            [ISConfigOption.MESSAGE]: event.target.value,
          }))}
          placeholder={texts.emailBodyPlaceholderText}
          multiline
          rows={8}
          error={isMsgBodyError}
          helperText={isMsgBodyError && texts.required}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.subject}</ActionLabel>
        <TextField
          value={iSSettings[ISConfigOption.SUBJECT]}
          onChange={(event) => setISSettings((prevState) => ({
            ...prevState,
            [ISConfigOption.SUBJECT]: event.target.value,
          }))}
          placeholder={texts.subjectPlaceholderText}
          error={isSubjectError}
          helperText={isSubjectError && texts.required}
        />
      </SettingsActionContainer>
      <SettingsSeparator />
      <Button
        onClick={handleISConfigSettingsSave}
        disabled={isContractLinkError || isMsgBodyError || isSubjectError}
      >
        <StyledSaveIcon />
        {texts.save}
      </Button>
    </SettingContainer>
  );
};

ISConfigs.propTypes = {
  selectedCoach: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default ISConfigs;
