import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useToast from '../../../../hooks/useToast';
import CoachConfig, { LeadConfigOptions } from '../../../../Model/CoachConfig';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import Button from '../../../../../components/v2/Button';

import { SettingOption } from '../../utils';
import {
  ActionLabel,
  HeaderContainer,
  SettingContainer,
  SettingsActionContainer,
  SettingsSeparator,
  SettingTitle,
  TextField,
  StyledSaveIcon,
} from '../../styles';
import texts from './texts.json';

const LeadsConfigs = ({
  selectedCoach,
  setIsLoading,
}) => {
  const [coachConfigDoc, setCoachConfigDoc] = useState(null);
  const [leadSettings, setLeadSettings] = useState({
    [LeadConfigOptions.IS_TEXT_BACK_ENABLED]: false,
    [LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]: false,
    [LeadConfigOptions.EMAIL_ADDRESS]: '',
    [LeadConfigOptions.IS_WELCOME_EMAIL_ENABLED]: false,
  });

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const coachConfigDocument = await CoachConfig.getCoachConfigByCoachId(selectedCoach.id);
      if (isComponentMountedRef.current) {
        setCoachConfigDoc(coachConfigDocument);
        setLeadSettings({
          [LeadConfigOptions.IS_WELCOME_EMAIL_ENABLED]: coachConfigDocument.isLeadWelcomeEmailEnabled,
          [LeadConfigOptions.IS_TEXT_BACK_ENABLED]: coachConfigDocument.isTextBackEnabled,
          [LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]: coachConfigDocument.isEmailResponseEnabled,
          [LeadConfigOptions.EMAIL_ADDRESS]: coachConfigDocument.coachEmailAddress,
        });
      }
      setIsLoading(false);
    };
    if (selectedCoach) {
      init();
    }
  }, [
    selectedCoach,
    isComponentMountedRef,
    setIsLoading,
  ]);

  const isEmailError = leadSettings[LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]
    && !leadSettings[LeadConfigOptions.EMAIL_ADDRESS];

  const handleLeadSettingsSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await coachConfigDoc.updateLeadConfig(leadSettings);
      showToast(texts.leadsSettingSaveSuccessful);
    } catch (error) {
      showToast(texts.leadSettingsSaveFailed, { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [
    coachConfigDoc,
    leadSettings,
    showToast,
    isComponentMountedRef,
    setIsLoading,
  ]);
  return (
    <SettingContainer>
      <HeaderContainer>
        <SettingTitle>{texts.leadsConfigurations}</SettingTitle>
      </HeaderContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.sendLeadWelcomeEmail}</ActionLabel>
        <RadioButtonGroup
          options={[
            { label: texts.enabled, value: SettingOption.ENABLED },
            { label: texts.disabled, value: SettingOption.DISABLED },
          ]}
          selectedOption={leadSettings[LeadConfigOptions.IS_WELCOME_EMAIL_ENABLED]
            ? SettingOption.ENABLED : SettingOption.DISABLED}
          onOptionChange={(value) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.IS_WELCOME_EMAIL_ENABLED]: value === SettingOption.ENABLED,
          }))}
        />
      </SettingsActionContainer>
      <SettingsSeparator />
      <SettingsActionContainer>
        <ActionLabel>{texts.textResponse}</ActionLabel>
        <RadioButtonGroup
          options={[
            { label: texts.enabled, value: SettingOption.ENABLED },
            { label: texts.disabled, value: SettingOption.DISABLED },
          ]}
          selectedOption={
            leadSettings[LeadConfigOptions.IS_TEXT_BACK_ENABLED] ? SettingOption.ENABLED : SettingOption.DISABLED
          }
          onOptionChange={(value) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.IS_TEXT_BACK_ENABLED]: value === SettingOption.ENABLED,
          }))}
        />
      </SettingsActionContainer>
      <SettingsSeparator />
      <SettingsActionContainer>
        <ActionLabel>{texts.emailResponse}</ActionLabel>
        <RadioButtonGroup
          options={[
            { label: texts.enabled, value: SettingOption.ENABLED },
            { label: texts.disabled, value: SettingOption.DISABLED },
          ]}
          selectedOption={
            leadSettings[LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]
              ? SettingOption.ENABLED : SettingOption.DISABLED
          }
          onOptionChange={(value) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]: value === SettingOption.ENABLED,
          }))}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.emailAddress}</ActionLabel>
        <TextField
          value={leadSettings[LeadConfigOptions.EMAIL_ADDRESS]}
          onChange={(event) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.EMAIL_ADDRESS]: event.target.value,
          }))}
          placeholder={texts.emailPlaceholderText}
          error={isEmailError}
          helperText={isEmailError && texts.required}
        />
      </SettingsActionContainer>
      <SettingsSeparator />
      <Button
        disabled={isEmailError}
        onClick={handleLeadSettingsSave}
      >
        <StyledSaveIcon />
        {texts.save}
      </Button>
    </SettingContainer>
  );
};

LeadsConfigs.propTypes = {
  selectedCoach: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default LeadsConfigs;
