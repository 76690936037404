import { DisallowedGender } from '../../../../../Model/Coach';

const texts = {
  disallowedGenders: {
    [DisallowedGender.MALE]: 'Male',
    [DisallowedGender.FEMALE]: 'Female',
    [DisallowedGender.PREFER_NOT_TO_ANSWER]: 'Prefer not to answer',
    [DisallowedGender.OTHER]: 'Other',
  },
  iSConfigurations: 'IS Configurations',
  automaticLeadMsgs: 'Send Automatic Lead Messages',
  calendlyLink: 'Calendly Link',
  calendlyLinkPlaceholderText: 'eg. https://calendly.com/username',
  disallowGenders: 'Disqualified Genders for Lead Disqualification',
  automaticDisqualificationEmail: 'Send Automatic Disqualification Email',
  contractLink: 'Contract Link',
  emailBody: 'Email Body',
  subject: 'Subject',
  contractLinkPlaceholderText: 'eg. https://app.system2.fitness/onboarding?leadId=xxxxxxxxx',
  emailBodyPlaceholderText: 'Email body',
  subjectPlaceholderText: 'Subject',
  enabled: 'Enabled',
  disabled: 'Disabled',
  required: 'This field is required',
  save: 'Save Changes',
  iSConfigurationsSaveSuccessful: 'IS configuration settings saved successfully',
  iSConfigurationsSaveFailed: 'Error changing the IS configuration settings',
};

export default texts;
