import React, {
  useState,
} from 'react';
import { Stack } from '@mui/material';

import ColoredHeader from '../../components/ColoredHeader';
import CoachSelect from '../../components/Leads/components/CoachSelect';
import LoadingOverlay from '../../components/LoadingOverlay';
import { AssignmentRole } from '../../../utils/internalAssignments';

import {
  Container,
  Settings,
  Title,
} from './styles';
import texts from './texts';
import ISConfigs from './components/ISConfigs';
import LeadsConfigs from './components/LeadConfigs';

const CoachConfigurations = () => {
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container>
      <ColoredHeader>
        <Stack spacing={5}>
          <Title>{texts.coachConfiguration}</Title>
          <CoachSelect role={AssignmentRole.INSIDE_SALES} onChange={setSelectedCoach} />
        </Stack>
      </ColoredHeader>
      {selectedCoach && (
        <Settings>
          <ISConfigs
            selectedCoach={selectedCoach}
            setIsLoading={setIsLoading}
          />
          <LeadsConfigs
            selectedCoach={selectedCoach}
            setIsLoading={setIsLoading}
          />
        </Settings>
      )}
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

export default CoachConfigurations;
