import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import format from 'string-template';
import moment from 'moment';
import { Tooltip } from '@mui/material';

import { ReactComponent as RefreshIcon } from '../../../../../assets/icons/refresh-icon.svg';
import { ReactComponent as LightRefreshIcon } from '../../../../../assets/icons/refresh-icon-2.svg';
import {
  AttentionTag,
  InfoTag,
  WarningTag,
  DisabledTag,
} from '../../../../../components/Tags';
import Select from '../../../../../components/Select';
import useRunOnInitialRender from '../../../../../hooks/useRunOnInitialRender';
import { DateFormat } from '../../../../../utils/date';
import MealPlanAssignmentContext from '../../../../context/MealPlanAssignmentContext';
import MealPlanContext from '../../../../context/MealPlanContext';
import {
  AddButton,
  EditButton,
  PrimaryButton,
} from '../../../../../components/Button/ActionButtons';
import { DietaryRestrictionText } from '../../../../Model/UserNutritionProfile';
import MealPlan, { MealPlanType } from '../../../../Model/MealPlan';
import {
  getValidMealPlanOptions,
  addRecipeToMealTimes,
  removeRecipeFromMealTimes,
  moveRecipeInMealPlan,
} from '../../../../utils/mealPlan';
import {
  RestrictionAllergens,
  AllergenTagText,
  getMacroAverages,
  getMacroNutrientsFromCalories,
} from '../../../../utils/meals';
import MealPlanView from '../../../../components/MealPlanView';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import useMealPlanGeneration from '../../../../hooks/useMealPlanGeneration';
import EditMealTimesModal from '../../ManageMealPlans/components/EditMealTimesModal';
import { NutritionalGoalsAction } from '../UserNutritionalGoals';
import AddRecipeModal from '../AddRecipeModal';
import MealPlanSelect from '../MealPlanSelect/MealPlanSelect';

import {
  Container,
  TitleContainer,
  ContentContainer,
  ItemContainer,
  Label,
  StyledTextField,
  ItemList,
  StyledDateContainer,
  LoadingIndicatorContainer,
} from './styles';
import texts from './texts';
import { MealPlanAssignOption } from './utils';

const UserMealPlan = ({
  isEditable,
  totalDailyCalories,
  scaleMeals,
  userId,
  macrosPercentages,
  defaultAction,
}) => {
  const {
    mealPlanAssignmentDoc = {},
    nutritionProfileDoc = {},
    nutritionalGoals = {},
    setNutritionalGoals,
    setNewMealPlanData,
    mealPlanAction,
    setMealPlanAction,
    nutritionalGoalsAction,
    assignmentNote,
    setAssignmentNote,
  } = useContext(MealPlanAssignmentContext);

  const {
    mealPlanViewsCollection: {
      docs: mealPlanViews = [],
    } = {},
  } = useContext(MealPlanContext);

  const { dietaryRestrictions: restrictions = [] } = nutritionProfileDoc || {};
  const {
    name: assignedMealPlanName = '',
    mealPlanId: assignedMealPlanId,
    approvalDate: assignmentApprovalDate,
    mealTimes: assignmentMealTimes = [],
  } = mealPlanAssignmentDoc || {};

  const assignOptions = useMemo(() => {
    const options = Object.values(MealPlanAssignOption).map((option) => ({
      label: texts.button[option],
      value: option,
    }));
    if (mealPlanAssignmentDoc?.exists) {
      return options;
    }
    // Remove previous meal plan option if no meal plan is assigned
    return options.filter((option) => option.value !== MealPlanAssignOption.USE_PREVIOUS_MEAL_PLAN);
  }, [
    mealPlanAssignmentDoc,
  ]);

  // Get valid, non archived, meal plans
  const validMealPlans = useMemo(() => (
    getValidMealPlanOptions(mealPlanViews)
  ), [
    mealPlanViews,
  ]);

  const getFilteredMealPlans = useCallback((filter = null) => {
    const resultMealPlans = validMealPlans || [];

    if (!filter) {
      return resultMealPlans;
    }

    // Filter by restrictions, in this case we should also filter live plans
    return resultMealPlans.filter((mealPlan) => {
      const { allergenTags, type } = mealPlan;
      return (
        type !== MealPlanType.LIVE
          && !allergenTags.some((tag) => RestrictionAllergens[filter].includes(tag))
      );
    });
  }, [
    validMealPlans,
  ]);

  // States used when assigning a new meal plan
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedMealPlanId, setSelectedMealPlanId] = useState('');
  const [selectedBucketId, setSelectedBucketId] = useState('');
  const [selectedMealPlanTemplateDoc, setSelectedMealPlanTemplateDoc] = useState(null);
  const [filteredMealPlans, setFilteredMealPlans] = useState(validMealPlans);
  // Meal times to show in meal plan view component
  const [newMealTimes, setNewMealTimes] = useState(!isEditable ? assignmentMealTimes : []);
  const [newMealPlanName, setNewMealPlanName] = useState(!isEditable ? assignedMealPlanName : '');
  const [isRecipeModalOpen, setIsRecipeModalOpen] = useState(false);
  const [isMealTimesModalOpen, setIsMealTimesModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { generateMealTimes } = useMealPlanGeneration();

  const openRecipeModal = (bucketId = null) => {
    setSelectedBucketId(bucketId);
    setIsRecipeModalOpen(true);
  };

  const closeRecipeModal = () => {
    setSelectedBucketId('');
    setIsRecipeModalOpen(false);
  };

  // Update filtered meal plans when meal plan views or filter changes
  useEffect(() => {
    setFilteredMealPlans(getFilteredMealPlans(selectedFilter));
  }, [
    getFilteredMealPlans,
    selectedFilter,
  ]);

  const filterOptions = {
    '': texts.allPlans,
    ...DietaryRestrictionText,
  };

  const filterSelectOptions = Object.entries(filterOptions).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const optionHandler = useMemo(() => ({
    [MealPlanAssignOption.NEW_MEAL_PLAN]: () => {
      if (selectedMealPlanId) {
        // Check if selected meal plan is in the filtered options
        if (filteredMealPlans.some((filteredMealPlan) => filteredMealPlan.id === selectedMealPlanId)) {
          // Don't update states, keep current meal plan selected
          setMealPlanAction(MealPlanAssignOption.NEW_MEAL_PLAN);
          return;
        }
      }
      // Reset states
      setSelectedMealPlanId('');
      setSelectedMealPlanTemplateDoc(null);
      setNewMealPlanData({});
      setNewMealTimes([]);
      setNewMealPlanName('');
      setMealPlanAction(MealPlanAssignOption.NEW_MEAL_PLAN);
    },
    [MealPlanAssignOption.USE_PREVIOUS_MEAL_PLAN]: () => {
      // Set states to assigned meal plan values
      setSelectedMealPlanId(assignedMealPlanId);
      setSelectedMealPlanTemplateDoc(null);
      setNewMealTimes(assignmentMealTimes);
      setNewMealPlanName(assignedMealPlanName);
      setMealPlanAction(MealPlanAssignOption.USE_PREVIOUS_MEAL_PLAN);
      setSelectedFilter('');
    },
  }), [
    assignedMealPlanId,
    assignmentMealTimes,
    assignedMealPlanName,
    setNewMealPlanData,
    setMealPlanAction,
    selectedMealPlanId,
    filteredMealPlans,
  ]);

  const newMacroAverages = useMemo(() => (
    getMacroAverages(newMealTimes || [])
  ), [newMealTimes]);

  const newMealTimesMetadata = useMemo(() => {
    // Get allergen tags and recipe ids
    const allAllergenTags = [];
    const recipes = [];
    newMealTimes?.forEach(({ meals }) => meals.forEach(({ recipe }) => {
      recipe.allergenTags?.forEach((tag) => allAllergenTags.push(tag));
      recipes.push(recipe.id);
    }));
    return {
      allergenTags: [...new Set(allAllergenTags)],
      recipes: [...new Set(recipes)],
    };
  }, [newMealTimes]);

  useRunOnInitialRender(() => {
    if (defaultAction) {
      optionHandler[defaultAction]();
    }
  });

  /*
    This useEffect handles meal plan changes, it sets the refresh flow's meal plan data (new macro averages, new
    meal times, new allergens, etc) and also sets new nutritional goals if needed (when the nutritional goals option
    is set to recommended).
  */
  useEffect(() => {
    if (!mealPlanAction) {
      return;
    }

    // Set refresh flow meal plan data
    const commonFields = {
      mealTimes: newMealTimes,
      name: newMealPlanName,
      macroAverages: newMacroAverages,
      allergenTags: newMealTimesMetadata.allergenTags,
      recipes: newMealTimesMetadata.recipes,
    };

    if (mealPlanAction === MealPlanAssignOption.NEW_MEAL_PLAN && selectedMealPlanId) {
      setNewMealPlanData({
        ...commonFields,
        mealPlanId: selectedMealPlanId,
      });
    } else if (mealPlanAction === MealPlanAssignOption.USE_PREVIOUS_MEAL_PLAN) {
      setNewMealPlanData({
        ...commonFields,
        mealPlanId: assignedMealPlanId,
      });
    }

    // Set recommended nutritional goals based on new meal plan content
    if (nutritionalGoalsAction === NutritionalGoalsAction.USE_RECOMMENDED_GOALS && newMacroAverages) {
      setNutritionalGoals((prevGoals) => ({
        ...prevGoals,
        macroAverages: {
          macros: getMacroNutrientsFromCalories(prevGoals?.totalDailyCalories, newMacroAverages.percentages),
          percentages: { ...newMacroAverages.percentages },
        },
      }));
    }
  }, [
    mealPlanAction,
    setNewMealPlanData,
    newMealTimes,
    newMacroAverages,
    selectedMealPlanId,
    newMealPlanName,
    assignedMealPlanId,
    newMealTimesMetadata,
    nutritionalGoalsAction,
    setNutritionalGoals,
  ]);

  const handleAddRecipe = (bucketId, recipe) => {
    setNewMealTimes((prev) => addRecipeToMealTimes(prev, bucketId, recipe));
  };

  const handleDeleteRecipe = (recipeId, bucketIndex) => {
    setNewMealTimes((prev) => removeRecipeFromMealTimes(prev, recipeId, bucketIndex));
  };

  const handleMoveRecipe = ({
    removedIndex,
    addedIndex,
    sourceBucketIndex,
    destinationBucketIndex,
    meal,
  }) => {
    setNewMealTimes((prevMealTimes) => moveRecipeInMealPlan(
      prevMealTimes,
      removedIndex,
      addedIndex,
      sourceBucketIndex,
      destinationBucketIndex,
      meal,
    ));
  };

  const handleFilterChange = useCallback((event) => {
    const filterValue = event.target.value;
    const newFilteredMealPlans = getFilteredMealPlans(filterValue);
    if (selectedMealPlanId) {
      // Check if selected meal plan is in the filtered options
      if (newFilteredMealPlans.some((filteredMealPlan) => filteredMealPlan.id === selectedMealPlanId)) {
        // Don't update states, keep current meal plan selected
        setSelectedFilter(filterValue);
        return;
      }
    }
    setSelectedFilter(filterValue);
    // Reset states
    setSelectedMealPlanId('');
    setNewMealPlanData({});
    setNewMealTimes([]);
    setNewMealPlanName('');
  }, [
    getFilteredMealPlans,
    setNewMealPlanData,
    selectedMealPlanId,
  ]);

  // Gets a live template as a parameter and returns a generated array of meal times
  const getGeneratedMealTimes = useCallback(async (mealPlanTemplate) => {
    const {
      macroAverages: {
        percentages: {
          protein,
          carbs,
          fat,
        } = {},
      } = {},
    } = nutritionalGoals;

    return generateMealTimes({
      mealPlanTemplate,
      restrictions,
      userId,
      ...((protein && carbs && fat) ? {
        targetProtein: protein,
        targetCarbs: carbs,
        targetFat: fat,
      } : {}),
    });
  }, [
    nutritionalGoals,
    restrictions,
    userId,
    generateMealTimes,
  ]);

  const handleMealPlanTemplateSelect = useCallback(async (event) => {
    const mealPlanId = event.target.value;
    setIsLoading(true);
    const mealPlanTemplateDoc = await MealPlan.getMealPlan(mealPlanId);
    const {
      type,
      name,
      mealTimes,
    } = mealPlanTemplateDoc;

    if (type === MealPlanType.PREDEFINED) {
      setNewMealTimes(mealTimes);
    } else {
      const generatedMealTimes = await getGeneratedMealTimes(mealPlanTemplateDoc);
      setNewMealTimes(generatedMealTimes);
    }
    setSelectedMealPlanTemplateDoc(mealPlanTemplateDoc);
    setSelectedMealPlanId(mealPlanId);
    setNewMealPlanName(name);
    setIsLoading(false);
  }, [
    getGeneratedMealTimes,
  ]);

  // Re-generates and sets new meal times
  const handleRegeneratePlan = useCallback(async () => {
    setIsLoading(true);
    const generatedMealTimes = await getGeneratedMealTimes(selectedMealPlanTemplateDoc);
    setNewMealTimes(generatedMealTimes);
    setIsLoading(false);
  }, [
    getGeneratedMealTimes,
    selectedMealPlanTemplateDoc,
  ]);

  const renderItemList = useCallback(({
    items,
    itemsTexts,
    component: ItemComponent,
    visibleAmount = 4,
  }) => {
    // Show first {visibleAmount} items as badges and remaining ones as a tooltip.
    const firstItems = items.slice(0, visibleAmount);
    const remainingItems = items.slice(visibleAmount, items.length);
    const tooltipText = remainingItems.map((it) => itemsTexts[it]).join(', ');
    return (
      <ItemList>
        {firstItems.map((item) => (
          <ItemComponent key={`list-item-${item}`}>
            {itemsTexts[item]}
          </ItemComponent>
        ))}
        {!!remainingItems.length && (
          <Tooltip
            title={tooltipText}
            placement="top"
            arrow
          >
            <ItemComponent>
              {format(texts.moreItems, { amount: remainingItems.length })}
            </ItemComponent>
          </Tooltip>
        )}
      </ItemList>
    );
  }, []);

  // Only show the regenerate button when a live template is selected
  const showRegenerateButton = !isLoading && selectedMealPlanTemplateDoc?.type === MealPlanType.LIVE;

  return (
    <Container>
      <TitleContainer>{texts.mealPlan}</TitleContainer>
      {isEditable && (
        <ContentContainer>
          <RadioButtonGroup
            options={assignOptions}
            selectedOption={mealPlanAction}
            onOptionChange={(value) => optionHandler[value]?.()}
          />
        </ContentContainer>
      )}
      {(!!mealPlanAction || !isEditable) && (
        <>
          <ContentContainer>
            {(isEditable && mealPlanAction === MealPlanAssignOption.NEW_MEAL_PLAN) && (
              <>
                <ItemContainer>
                  <Label>{texts.filterBy}</Label>
                  <Select
                    value={selectedFilter}
                    onChange={handleFilterChange}
                    options={filterSelectOptions}
                    displayEmpty
                  />
                </ItemContainer>
                <ItemContainer>
                  <Label>{texts.selectPlan}</Label>
                  <MealPlanSelect
                    value={selectedMealPlanId}
                    onChange={handleMealPlanTemplateSelect}
                    mealPlanOptions={filteredMealPlans}
                  />
                </ItemContainer>
              </>
            )}
            <ItemContainer>
              <Label>{texts.name}</Label>
              <StyledTextField
                value={newMealPlanName}
                placeholder={texts.namePlaceholder}
                disabled={!isEditable}
                onChange={(e) => setNewMealPlanName(e.target.value)}
              />
            </ItemContainer>
            {isLoading && (
              <LoadingIndicatorContainer>
                <RefreshIcon />
                {texts.loadingMealPlan}
              </LoadingIndicatorContainer>
            )}
            {showRegenerateButton && (
              <PrimaryButton
                onClick={handleRegeneratePlan}
                variant="dark"
                size="medium"
                icon={<LightRefreshIcon />}
              >
                {texts.regeneratePlan}
              </PrimaryButton>
            )}
            {(!!assignmentApprovalDate && mealPlanAction === MealPlanAssignOption.USE_PREVIOUS_MEAL_PLAN) && (
              <StyledDateContainer>
                {format(texts.updateDate, {
                  date: moment(assignmentApprovalDate.toDate()).format(DateFormat.MONTH_NAME_DATE_FORMAT),
                })}
              </StyledDateContainer>
            )}
          </ContentContainer>
          <ContentContainer>
            <ItemContainer>
              <Label>{texts.note}</Label>
              <StyledTextField
                value={assignmentNote}
                onChange={(e) => setAssignmentNote?.(e.target.value)}
                placeholder={texts.notePlaceholder}
                disabled={!isEditable}
              />
            </ItemContainer>
            {!!newMacroAverages?.percentages?.protein && (
              <ItemContainer>
                <Label>{texts.macros}</Label>
                <ItemList>
                  <InfoTag>
                    {ReactHtmlParser(format(texts.protein, { percentage: newMacroAverages.percentages.protein }))}
                  </InfoTag>
                  <InfoTag>
                    {ReactHtmlParser(format(texts.carbs, { percentage: newMacroAverages.percentages.carbs }))}
                  </InfoTag>
                  <InfoTag>
                    {ReactHtmlParser(format(texts.fat, { percentage: newMacroAverages.percentages.fat }))}
                  </InfoTag>
                </ItemList>
              </ItemContainer>
            )}
            {!!restrictions.length && (
              <ItemContainer>
                <Label>{texts.dietaryCompliance}</Label>
                {renderItemList({
                  items: restrictions,
                  itemsTexts: DietaryRestrictionText,
                  component: WarningTag,
                })}
              </ItemContainer>
            )}

            <ItemContainer>
              <Label $isAllergens>{texts.allergens}</Label>
              {newMealTimesMetadata.allergenTags.length
                ? renderItemList({
                  items: newMealTimesMetadata.allergenTags,
                  itemsTexts: AllergenTagText,
                  component: AttentionTag,
                  visibleAmount: 2,
                })
                : (
                  <ItemList>
                    <DisabledTag>
                      {texts.noAllergens}
                    </DisabledTag>
                  </ItemList>
                )}
            </ItemContainer>
          </ContentContainer>
          {(isEditable && !!selectedMealPlanId) && (
            <ContentContainer>
              <AddButton onClick={() => openRecipeModal()}>{texts.addRecipes}</AddButton>
              <EditButton onClick={() => setIsMealTimesModalOpen(true)}>{texts.updateMealTimes}</EditButton>
            </ContentContainer>
          )}
          <ContentContainer>
            <MealPlanView
              mealTimes={newMealTimes}
              scaleMeals={scaleMeals}
              totalDailyCalories={totalDailyCalories}
              macrosPercentages={macrosPercentages}
              allowEdit={isEditable}
              handleDelete={handleDeleteRecipe}
              onAddRecipe={openRecipeModal}
              moveMeal={handleMoveRecipe}
            />
          </ContentContainer>
        </>
      )}
      <AddRecipeModal
        isOpen={isRecipeModalOpen}
        onAddRecipe={handleAddRecipe}
        mealTimes={newMealTimes}
        onClose={closeRecipeModal}
        selectedBucketId={selectedBucketId}
      />
      {isMealTimesModalOpen && (
        <EditMealTimesModal
          isOpen={isMealTimesModalOpen}
          onClose={() => setIsMealTimesModalOpen(false)}
          mealPlanMealTimes={newMealTimes}
          setMealPlanMealTimes={setNewMealTimes}
        />
      )}
    </Container>
  );
};

UserMealPlan.propTypes = {
  isEditable: PropTypes.bool,
  totalDailyCalories: PropTypes.number,
  scaleMeals: PropTypes.bool,
  macrosPercentages: PropTypes.object,
  defaultAction: PropTypes.string,
  userId: PropTypes.string,
};

UserMealPlan.defaultProps = {
  isEditable: true,
  totalDailyCalories: null,
  scaleMeals: false,
  userId: '',
  macrosPercentages: null,
  defaultAction: '',
};

export default UserMealPlan;
